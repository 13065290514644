import React from "react";
import "./homeScreen.css";
import { NavLink } from "react-router-dom";

import ContactScreen from "../ContactScreen/ContactScreen";
import CategoryCard from "../../components/Categories/CategoryCard";




export default function HomeScreen() {
  return (
    <div className=" bg-cyan-900 " id='top'>

      <div className="acc w-5/6 bg-white  shadow text-center  mb-28   p-8" >
        {/* <div>
          <img src="" alt="" className="m-auto "/>
          </div> */}
        {/* <div className="flex  text-xl text-white justify-center align-items  m-auto mb-8 space-y-8 ">
          <h2 className=" text-3xl">Photos</h2>
          <h2 className=" text-3xl">Logos</h2>
          <h2 className=" text-3xl">Animations</h2>
          <h2 className=" text-3xl">Sites vitrines</h2>
          <h2 className=" text-3xl">Maquettes et Prototypes</h2>
          </div> */}
        <div className="pt-56  m-auto">
          <div  className=" m-auto bg-black bg-opacity-10 backdrop-blur-md  md:w-3/6 p-8 shad rounded   text-center">
          <h1 className="nom text-xl text-cyan-400 ">Astra Lux</h1>
      
          <h2 className="text-white    px-8 ml-4 pt-24">Photographie / web Design / Infographie</h2> <br />
          <h2 className='text-xl  text-justify m-auto mt-8  md:text-center   font-bold text-white rounded p-2 mx-48 '>à CAUDRY</h2> 
        {/* <i className="fa-regular fa-circle-down text-white mt-8 opacity-20 text-9xl mt-48 " ></i> */}
        </div>
        </div>
      </div>


      <section className="w-full flex flex-wrap bg-cyan-900  items-center rounded  justify-center items-center mb-28 text-center p-0 ">
          <div className="flex flex-wrap justify-center items-center w-full mb-8 ">
          {/* <img src="assets/img/zoenb.jpg" alt="" className="object-cover md:w-1/4 p-0 rounded shadow shadow-black "/> */}
      <div className="flex-col    justify-center items-center space-y-8  ml-2 ">
          <h1 className=" text-6xl text-white text-center ">A propos</h1><br />
         <p className="md:w-1/2 m-auto text-justify  text-white p-4">
         <strong className="text-xl text-cyan-400"> ASTRA LUX</strong> est l’allié de votre <strong className="text-xl text-cyan-400">image visuelle</strong>. <br />
         Portée par une passion pour les arts visuels, j'offre des solutions sur mesure pour sublimer votre 
         <strong className="text-xl text-cyan-400">identité</strong>. <br/><br/>De la <strong className="text-3l text-cyan-400">photographie professionnelle</strong> 
         à la création de <strong className="text-xl text-cyan-400">sites vitrines</strong>, en passant par la conception de logos, bannières, animations 2D et overlays pour streamers,
          nous mettons notre expertise à votre service. 
        <br /> <br/>Avec <strong className="text-xl text-cyan-400">ASTRA LUX</strong>, boostez votre visibilité et affirmez votre présence avec des créations uniques et impactantes.
          </p> 
          <br />
          <button className=" rounded   p-8 hover:shad   m-auto text-center bg-cyan-800 center ">
            <NavLink to="/galerie" className=" text-4xl px-8 text-white m-auto  text-center">Mes projets</NavLink>
          </button>
         

        </div>
        </div>
      </section>
<section>
      
  </section>  
      <section className="bg-zinc-100 text-center py-8 px-4 ">
        <h1 className="text-cyan-800 text-5xl mb-16 py-8">Offres promotionnelles</h1>
  <h1 className="text-cyan-800 text-start md:ml-16 text-4xl  pt-8">Pack starter entreprise : 400 €</h1>
  <div className="  text-center">
          

          <div className="flex-col rounded p-4">
   
    <hr className="w-3/6 p-0 m-auto" />
    <div className="flex flex-wrap md:items-start md:justify-center justify-center md:space-x-8">
      {/* Carte Cartes de visite */}
      <div className="flex-col card md:w-2/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-800 my-4">
          Cartes de visite
        </h3>
        <img
          src="assets/img/carteV.jpg"
          alt="Cartes de visite"
          className="object-contain  p-0 m-auto"
        />
        <p className="text-cyan-700 text-2xl">125 €</p>
        <p className="text-zinc-700 text-sm">Design personnalisé pour vos cartes.</p>
      </div>

      {/* Carte Logo */}
      <div className="flex-col card md:w-2/6 w-5/6 shadow shadow-black pt-2 my-8 rounded justify-center items-center space-y-8 text-center p-0 pb-2">
        <h3 className="font-medium text-3xl text-cyan-700 my-4">
          Logo
        </h3>
        <img
          src="assets/img/logoC.png"
          alt="Logo"
          className="object-fill  p-0 m-auto"
        />
        <p className="text-cyan-700 text-2xl">375 €</p>
        <p className="text-zinc-700 text-sm">Création d'un logo unique pour votre marque.</p>
      </div>
</div>
</div>

</div>

     


          
          <br /><br />
         <button className="  rounded   p-8 hover:shad   m-auto text-center bg-cyan-800 center ">
            <NavLink to="/prestations" className=" text-4xl text-white m-auto  text-center">Voir plus ...</NavLink>
          </button> 
      </section>
       
        <h1 className="text-white text-center text-6xl mt-48  ">Photographie</h1>
      <section className=" md:flex lg:flex-row flex-wrap justify-center   m-auto space-x-4  ">

      <NavLink to='/prestations#entreprises' className="   lg:w-1/4  ">
      
        <div className="flex-col shadow shadow-black pt-2  my-8 rounded mx-8 card  justify-center items-center space-y-8 text-center p-0 ">
          <h3 className="font-medium text-3xl text-white my-4">Entreprises</h3>
          <img src="assets/img/bur.jpg" alt="" className="object-cover  p-0 m-auto"/>
          
      
        
         
       

        </div>
            </NavLink>
       
            <NavLink to='/prestations' className="   lg:w-1/4  ">
        <div className="flex-col shadow shadow-black pt-2 my-8 rounded  mx-8 card justify-center items-center space-y-8 text-center p-0 ">
          <h3 className="font-medium text-3xl text-white hover:text-cyan-300 my-4">Animaux</h3>
          <img src="assets/img/cloé2.jpg" alt="" className="object-cover  p-0 m-auto"/>
        
         
       

        </div>
            </NavLink>
       
            <NavLink to='/prestations' className="  lg:w-1/4  ">
        <div className="flex-col shadow shadow-black pt-2 my-8 rounded  mx-8 card justify-center items-center space-y-8 text-center p-0 ">
          <h3 className="font-medium text-3xl text-white my-4">Portraits</h3>
          <img src="assets/img/thomas.JPG" alt="" className="object-cover  p-0 m-auto"/>
        
         
          

        </div>
            </NavLink>
        </section>
        <h1 className="text-white text-center text-6xl mt-28 ">Web Design</h1>
        <section className=" md:flex lg:flex-row flex-wrap justify-center   m-auto space-x-4">
          <div></div>

            <NavLink to='/prestations#infographie' className="  lg:w-1/4   ">
        <div className="flex-col shadow shadow-black pt-2  my-8 rounded mx-8 card justify-center items-center space-y-8 text-center p-0 ">
          <h3 className="font-medium text-3xl text-white my-4">Logos</h3>
          <img src="assets/img/utile/logoRose.png" alt="" className="object-contain  p-0 m-auto"/>
        
         
       

        </div>
            </NavLink>
       
            <NavLink to='/prestations' className="   lg:w-1/4  ">
        <div className="flex-col shadow shadow-black pt-2 my-8 rounded card mx-8 justify-center items-center space-y-8 text-center p-0 ">
          <h3 className="font-medium text-3xl text-white my-4">Maquettes</h3>
          <img src="assets/img/maquette.png" alt="" className="object-cover  p-0 m-auto"/>
        
         
       

        </div>
            </NavLink>
       
            <NavLink to='/prestations' className="   lg:w-1/4  ">
        <div className="flex-col shadow shadow-black pt-2 my-8 rounded card mx-8 justify-center items-center space-y-8 text-center p-0 ">
          <h3 className="font-medium text-3xl text-white my-4">Sites</h3>
          <img src="assets/img/utile/site_vignette.png" alt="" className="object-cover  p-0 m-auto"/>
        
         
       

        </div>
            </NavLink>
       
       

      </section>
  
     
   {/* <ContactScreen/> */}
   <div className="py-32 m-auto text-center bg-white">
    <button className="hover:shad bg-cyan-900 rounded p-12 m-auto">
     <NavLink to='/contact'> <h2 className="text-5xl text-center  m-auto text-white">Contact</h2></NavLink>
    </button>
   </div>
    </div>

    
  );
}
